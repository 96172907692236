<template>
    <div class="login-wrap">
        <!-- <v-sheet  rounded="sm" shaped >
            <v-container fluid>
                <v-row>
                    <v-col cols="12" md="12">
                        <h1>The Illium Platform</h1>
                    </v-col>
                    <v-col sm="12" md="12">
                        <h5>Login</h5>
                    </v-col>
                    <v-col cols="12" md="6">
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet> -->
        <v-container fluid>
            <v-row>

                <!-- what can you do  -->
                <v-col cols="12" md="12">
                    <v-sheet class="pa-1" rounded="sm">
                        <v-container fluid>
                            <v-row>
                                <v-col sm="12" md="12">
                                    <h2>{{ what_can_you_do.header }}</h2>
                                    <!-- <v-btn class="ma-0"  elevation="2" small light block @click="speak('what can you do?')" >speak('what can you do?')</v-btn> -->
                                </v-col>
            
                            </v-row>
                            <v-row>
            
                                <v-col cols="12" md="4">
                                    <v-card class="mx-auto explain-card" max-width="100%"  elevation="4" >
                                        <v-img height="250" :src="`${db_url}static/img/random/intel_card.jpg`"> </v-img>
                                        <v-list-item three-line>
                                            <v-list-item-content>
                                                <v-list-item-title class=" card-title text-h5 mb-1">  1. You can Gain Insight </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Know whats going on in the Jungle of the Market. 
                                                </v-list-item-subtitle>
                                                <div class=" pa-1 text--disabled description">
                                                    Categorize and group tokens, traders , or other assets into portfolios.  Stay up to date with the market by having Arcadia report to you on any price or other action.  Through chat, platform , or API. Then use this intel to either  make smart trading decisions, or maintain/build a community with the latest intel.
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-card-actions>
                                            
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-card class="mx-auto explain-card" max-width="100%" elevation="4" >
                                        <v-img height="250" :src="`${db_url}static/img/random/agent_card.jpg`"> </v-img>
                                        <v-list-item three-line>
                                            <v-list-item-content>
                                                <v-list-item-title class=" card-title text-h5 mb-1">  2. You can Build Agents </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    
                                                    Build automations based on Intel 
                                                </v-list-item-subtitle>
                                                <div class=" pa-1 text--disabled description">
                                                    Use your newly found insights to make trades or do any other action.
            Arcadia offers a fully customizable,  fully automated, framework for you to build any type of Agent that will trade, make calls, or any other type of action on your behalf. Our back-testing framework will test your Agent before putting it live and on the marketplace if you decide to do so.
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-card-actions>
                                            
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-card class="mx-auto explain-card" max-width="100%" elevation="4" >
                                        <v-img height="250" :src="`${db_url}static/img/random/market_card.jpg`"> </v-img>
                                        <v-list-item three-line>
                                            <v-list-item-content>
                                                <v-list-item-title class=" card-title text-h5 mb-1"> 3. You can Offer or Find Portfolios . </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    
                                                    Help others invest / Find smart investments.
                                                </v-list-item-subtitle>
                                                <div class=" pa-1 text--disabled description">
                                                    If you have an idea for a bot or agent, that you would like to share with a community you can easily make this available on Arcadia. This platform will automate the coding, execution and reporting of your idea. Alternatively if you don't know what to invest in you can browse the Arcadia marketplace for Agents others have build and shared, and invest with them to share in their success. Furthermore, you have full transparency on the quality of agents, as you can use the back-tester to gage the quality of any agent and see the  historical returns before  adopting, publishing or putting it live.
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-card-actions>
                                            
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
            
                            </v-row>
                        </v-container>
                    </v-sheet>
                    
                </v-col>
                <!-- who can you be  -->
                <v-col sm="12" md="12">
                    <v-sheet class="ma-1" rounded="sm">
                        <v-container fluid>
                            <v-row>
                                <v-col sm="12" md="12">
                            <h2>Who can you be ?</h2>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-card class="mx-auto explain-card" max-width="100%" elevation="4" >
                                <v-img height="250" :src="`${db_url}static/img/random/role_engineer_card.jpg`"> </v-img>
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-item-title class=" card-title text-h5 mb-1">  The  Engineer </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Creates Data and Metrics for builders.                                 
                                        </v-list-item-subtitle>
                                        <div class=" pa-1 text--disabled description">
                                            Work on layer 0 of the system and offer new base metrics, the very building blocks on which the entire system is built. You provide data pipelines on which the system can be run. And make money off agents that use your data. It all starts here.
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-card-actions>
                              
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-card class="mx-auto explain-card" max-width="100%" elevation="4" >
                                <v-img height="250" :src="`${db_url}static/img/random/role_builder_card.jpg`"> </v-img>
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-item-title class=" card-title text-h5 mb-1">  The  Builder </v-list-item-title>
                                        <v-list-item-subtitle>
            
                                            Creates Portfolios and Agents
                                        </v-list-item-subtitle>
                                        <div class=" pa-1 text--disabled description">
                                            Do market research on strategies, Agents, and products that could work . Build Agents, backtest them, create Neural Nets from them, and offer them to merchants and investors. alternatively you could use them for your own agents.
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-card-actions>
                              
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-card class="mx-auto explain-card" max-width="100%" elevation="4" >
                                <v-img height="250" :src="`${db_url}static/img/random/role_merchant_card.jpg`"> </v-img>
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-item-title class=" card-title text-h5 mb-1">  The  Merchant </v-list-item-title>
                                        <v-list-item-subtitle>
            
                                            Creates Brands and offers Agents
                                        </v-list-item-subtitle>
                                        <div class=" pa-1 text--disabled description">
                                            Browse the market place for Agents offered by builders. Take them out of the platform by creating a separate product with its own brand , or promote them on platform to find investors.
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-card-actions>
                              
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-card class="mx-auto explain-card" max-width="100%" elevation="4" >
                                <v-img height="250" :src="`${db_url}static/img/random/role_investor_card.jpg`"> </v-img>
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-item-title class=" card-title text-h5 mb-1">  The  Investor </v-list-item-title>
                                        <v-list-item-subtitle>
            
                                            Invests in Products, Portfolios and Agents 
                                        </v-list-item-subtitle>
                                        <div class=" pa-1 text--disabled description">
                                            Not sure what to invest in ? Browse the products offered by merchants and builders , and in one click invest in their Agents. Arcadia provides and easy way to do due diligence , and get involved in crypto investing today.
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-card-actions>
                              
                                </v-card-actions>
                            </v-card>
                        </v-col>
                            </v-row>
                        </v-container>
                    </v-sheet>
                    
                </v-col>
                <!-- Levels of builders -->
                <v-col sm="12" md="12">
                    <v-container fluid>
            
                        <v-row>
                            <v-col sm="12" md="12">
                                <v-sheet rounded="sm">
                                    <h2>Levels Of the Builders</h2>
                                    <table v-for="lvl in builder_levels" :key="lvl.level_index" class="layers-table custom-table pa-1">
                                        <tbody v-if="is_mobile">
                                            <tr>
                                                <th>
                                                    <small>
                                                        Level {{ lvl.level_index }} 
                                                    </small>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>{{ lvl.level_name }}</th>
                                            </tr>
                                            <tr>
                                                <td v-html="lvl.content_html"></td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <th class="cell-width-1" > Level {{ lvl.level_index }}</th>
                                                <th class="cell-width-2" >{{ lvl.level_name }}</th>
                                                <td class="cell-width-3" v-html="lvl.content_html"></td>
                                            </tr>
            
                                        </tbody>
                                    </table>
                                   
                                </v-sheet>
                                
                            </v-col>
                        </v-row>
                    </v-container>
                    
                </v-col>
            </v-row>
        </v-container>




    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import FormUserPwLogin from './form-user-pw-login.vue'
import MetamaskLogin from './metamask-login.vue'
import StreamLoadingActions from './stream-loading-actions.vue'
export default {
    name: 'view-about-explain',
    components:{
        MetamaskLogin,
        StreamLoadingActions,
        FormUserPwLogin 

    },
    emits:[
    ],
   data(){return {
        model_open:true,
        what_can_you_do:{
            header : 'What can you do?',
            sections:[
                {
                    header: '1. Gain Insight',
                    subheader:'Know whats going on in the Jungle of the Market.',
                    body:'Categorize and group assets into portfolios.   Stay up to date with the market by having arcadia report to you on any price or other action.  Through chat, platform , or API. then use this intel to either  make smart trading decisions, or keep/build a community up to date with the latest intel.'
            
                }
            ]
        },
        builder_levels:[
            {level_index :0 , level_name:'Metrics', content_html:` <div>

                Everything starts with <b>data</b>. <br>
                Metrics are simple data points that can be linked to a token, trader or a chat group.<br>
                Metric sets that are not linked to a certain object, are called oracles. 
                Engineers constantly add new oracles with external sources of data.<br>
                Metrics are simple points usually linked to a time; such as price, calls, liquidity and so forth. <br><br>
                They are just facts. What happened, when, and how much.<br> 
                From these you can get good intelligence of what the world is doing.
                </div>`},
            {level_index :1 , level_name:'Static Portfolios', content_html:` <div>

                When you have looked at the metrics, and see an object of interest like a token, trader or a chat group,
                                    you can save and classify them in a list. <br> 
                                    These lists are called static portfolios.<br>
                                    Make your own lists based on your goals, and look back at them to see patterns, to understand how the market works, <br>
                                    and to fomo hard on how much you could've made if you'd only invested in them and not just added them to a static list. 
                </div>`},
            {level_index :2 , level_name:'Dynamic Portfolios', content_html:`                                <table>
                                    <tr>
                                        <th>Logic Based</th>
                                        <th>Neural Based</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Logic.
                                            These are portfolios where assets come and go based on rules that you define. <br>
                                            When you start to see patterns using the metrics of your static lists,  you can start to define rules. <br>
                                            For example you can make a portfolio that only has tokens that have a liquidity of more than one ETH, 
                                            and that are younger than three days. 
                                            <br>
                                            You can also add your static portfolios, as white lists or black lists to your dynamic portfolio. 
                                            <br><br>
                                            If you have a hunch that the rules you define, filter out tokens that will increase in price, you can now : 
                                            <br>
                                            <ol>
                                                <li>
                                                    Publish this list on your twitter or website and become an "influencer".
                                                </li>
                                                <li>
                                                    Be content with this as good trading intel, then fomo in by hand using uni-swap (slow).
                                                </li>
                                                <li>
                                                    Set some automated action on them - like buy - using Arcadia in the next level (chad)
                                                </li>

                                            </ol>
                                
                                        </td>
                                        <td>
                                            Neural.
                                            If it is difficult to see patterns in your static portfolios, 
                                            <br> you can use the power of AI to learn on your static portfolios. <br>
                                            Ask the AI : what type of tokens are in my static portfolio? <br><br>
                                            And then ask it : what other tokens are there being launched or traded that should belong in this list?
                                            <br>
                                            <small>(coming soon)</small>
                                        </td>
                                    </tr>
                                </table>`},
            {level_index :3 , level_name:'Agents', content_html:` When you have a bunch of dynamic portfolios that you're fomoing hard on, 
                                    you can now add automated actions to them. ( thats a chad move) <br>
                                    This means that if an asset enters that portfolio, a certain action is immediately automated and executed. <br> 
                                    Actions can be anything including buy, sell, make a call, notify you, even launch a contract, or tweet <br>
                                    You can then arrange these dynamic portfolios and assign actions to these lists. <br>
                                    What does this mean? If a token that has been bought, left dynamic portfolio A (state 1), it will automatically be qued to enter another list with another action (state 2). <br>
                                    For example you have 2 lists representing 2 states ; the buy list and the sell list <br>  a token passes the conditions of the buy list, is automatically bought , then waits to enter the sell list, which will - if the conditions are met  - sell the asset- <br>
                                
                                    Whats more, is that you can back-test these agents on historical data before you put them live. 
                                    <br> to give you an indication of how well it would do. 
                                    <br> you can also brag about and share (in a secured way) your back-tested and historical stats, to then get external capital to fomo in and multiply your returns in level 4. 
                                    <br> 
                                    <br><br>This system of states, lists and actions is called the agent, in which you can automate anything you can think of, based on the intelligence you gathered in previous levels.`},
            {level_index :4 , level_name:'Execution', content_html:`              When you have a profitable agent, you can then set it live either for yourself or for other users. <br>
                                    Again, You have options here. 

                                    <ol>
                                        <li>Make money by investing in the agent yourself. </li>
                                        <li>Or connect external self branded websites to the Arcadia API to offer the agent as a service or product.</li>
                                    
                                        <li>Or offer the agent on Arcadia marketplace, so that other investors can catch an ROI from it, while you take a percentage of all its profitable trades.</li>
                                    </ol>
                                    <br>
                                    The choice is yours ! You own it now, so you can make it work for you.`},
            
        ]
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
        do_log_in(){
            window.location = `${this.db_url}admin/login/?next=${window.location.origin}`
        },
        speak(text){
            text = this.what_can_you_do.sections[0].body
            console.log('speaking', text);
            const speech = new SpeechSynthesisUtterance(text);
            window.speechSynthesis.speak(speech);
        }
    },
    computed:{
        ...mapState(['db_url','is_mobile']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th, .custom-table td {
}

.cell-width-1 {
  width: 15%;
}

.cell-width-2 {
  width: 15%;
}

.cell-width-3 {
  width: 70%;
}



.login-wrap{
    width:100%;
    min-height: 30vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top:1em;
}
p{
    max-width: 30vw;
    min-width: 200px;
    text-align: center;
    color:rgba(255, 255, 255, 0.842);
}
.description{
    font-size:0.8em
}

.layers-table{
    tr,td{
        background:rgba(0, 0, 0, 0.03)
    }
    th{
        white-space: nowrap;
        padding:1em;
    }
    td{
        text-align: center;
        padding:1em;
        font-size: 13px;
    }
    tr:hover{
        background: none;
    }
    li{
        text-align: left;
    }
}

.explain-card{
    height:100%;
}
</style>